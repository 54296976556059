select.react-datepicker__month-select,
select.react-datepicker__year-select {
    height: 20px;
    border: 0;
    border-radius: 0;
    height: 30px;
    padding: 4px 4px 4px 4px;
}

.react-datepicker__year-dropdown-container--select,
.react-datepicker__month-dropdown-container--select,
.react-datepicker__month-year-dropdown-container--select,
.react-datepicker__year-dropdown-container--scroll,
.react-datepicker__month-dropdown-container--scroll,
.react-datepicker__month-year-dropdown-container--scroll {
    display: inline-block;
    margin: 0 !important;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
    display: none !important;
}

.react-datepicker__header {
    text-align: center;
    background-color: #ffffff;
    border-bottom: 0;
    border-top-left-radius: 0;
    padding: 8px 0;
    position: relative;
}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow,
.react-datepicker__navigation-icon::before {
    border-color: #000;
    border-style: solid;
    border-width: 3px 3px 0 0;
    content: "";
    display: block;
    height: 9px;
    position: absolute;
    top: 16px;
    width: 9px;
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle {
    display: none;
}


.react-datepicker__day--outside-month {
    color: #CCCCCC;
}

.react-datepicker__header__dropdown.react-datepicker__header__dropdown--select {
    border: 1px solid #C7C8CA;
    margin-left: 50px;
    margin-right: 50px;
    border-radius: 4px;
    position: relative;

}

select.react-datepicker__year-select {
    border-left: 1px solid #C7C8CA;
}

.react-datepicker__day-names {
    margin-bottom: -12px;
    margin-top: 15px;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
    border-radius: 0.3rem;
    background-color: #DD9B99;
    color: #fff;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
    border-radius: 0.3rem;
    background-color: #DD9B99;
    color: #fff;
}

.react-datepicker__day.react-datepicker__day--025.react-datepicker__day--today {
    background-color: #DD9B99;
    border-radius: 7px;
    color: #fff;
}

.button-footer {
    width: 95%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}

.react-datepicker__children-container {
    width: 100%;
}

.btn-cancel-datepicker {
    height: 25px;
    width: 60px;
    border: 1px solid #DD9B99;
    background-color: #fff;
    padding: 3px;
    right: 10px !important;
    position: relative;
    color: #DD9B99;
    border-radius: 5px;
}

.react-datepicker__day--highlighted,
.react-datepicker__month-text--highlighted,
.react-datepicker__quarter-text--highlighted,
.react-datepicker__year-text--highlighted {
    border-radius: 0.3rem;
    background-color: #DD9B99;
    color: #fff;
}

.react-datepicker__day--highlighted:hover,
.react-datepicker__month-text--highlighted:hover,
.react-datepicker__quarter-text--highlighted:hover,
.react-datepicker__year-text--highlighted:hover {
    background-color: #DD9B99;
}

.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--in-range:hover {
    background-color: #DD9B99;
}


.btn-ok-datepicker {
    margin-left: 10px;
    height: 25px;
    width: 45px;
    border: 1px solid #DD9B99;
    background-color: #CD3B2C;
    /* padding: 3px; */
    right: 10px !important;
    position: relative;
    color: #fff;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 700;
}

/* Form */
.form-size {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 10px;
}

.form-size-datepicker {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin: 10px;
}

.select-form {
    width: 228px;
}

.form-row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: 5px !important;
    margin-left: 5px !important;
    padding: 10px !important;
    /* margin: 0px; */
}

.form-group {
    margin-bottom: 1rem;
    width: 227px;
    margin-right: 17px;
}

.form-control {
    display: block;
    width: 100%;
    height: 36px !important;
    font-size: 14px;
    line-height: 1.42857143;
    color: #000 !important;
    background-color: #fff;
    background-image: none;
    border: 1px solid #C7C8CA !important;
    border-radius: 5px;
    box-shadow: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: #000;
    font-size: 14px;
    font-weight: bold;
    margin: 0px 0px 10px 0px;
    font-family: 'Arial';
}

.action-button {
    display: flex;
    justify-content: flex-end;
    margin: 20px;
    gap: 10px;


}

select.form-control:not([size]):not([multiple]) {
    height: 36px !important;
    border-radius: 5px !important;
}

.btn-primary {
    color: #fff;
    background-color: #DD9B99;
    border-color: #DD9B99;
    border-radius: 5px;
    margin-bottom: 20px;
}

.btn-outline-primary {
    color: #CD3B2C;

    border-color: #CD3B2C;
    border-radius: 5px;
    margin-bottom: 20px;
}


.btn.btn-primary:hover {
    background-color: #DD9B99 !important;
    border-color: #DD9B99 !important;
}

.btn.btn-primary:focus {
    background-color: #DD9B99 !important;
    border-color: #DD9B99 !important;
    box-shadow: 0 0 0 1px #DD9B99 !important;
}

.btn.btn-outline-primary:hover {
    color: #CD3B2C;
    background-color: #fff !important;
    border-color: #CD3B2C;
    border-radius: 5px;
    margin-bottom: 20px;
}

.btn-outline-primary:focus {
    color: #CD3B2C;
    background-color: #fff !important;
    border-color: #DD9B99 !important;
    box-shadow: 0 0 0 1px #DD9B99 !important;
}

@media screen and (max-width: 480px) {
    .form-size {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin: 10px;
    }
}

select option {
    margin: 500px !important;
    width: 1000px !important;
    background: #fff;
    border: 1px solid red;
    color: #000;
    padding: 10px;
    font-size: 14px;
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
}

option:not(:checked) {
    background-color: #fff !important;
    
}

option:hover {
    background-color: #DD9B99 !important;
}

option:checked {
    background: #DD9B99 !important;
    color: #fff !important;
}


.mandatory {
    color: red;
}
 