.tableFooter {
    background-color: #FFFFFF;
    padding: 8px 0px;
    width: 95%;
    font-weight: 500;
    text-align: left;
    font-size: 16px;
    color: #333333;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.button {
    border: none;
    padding: 7px 14px;
    border-radius: 5px;
    cursor: pointer;
    margin-right: 5px;
    margin-left: 5px;
    border: 1px solid #D9D9D9;
}
 

.activeButton {
    color: white;
    background: #CD3B2C;
}

.inactiveButton {
    color: #333333;
    background: #FFFFFF;
}