.state-change-data-form {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 5px;
}

.btn-export {
    color: #fff;
    background-color: #cd3b2c;
    border-color: #cd3b2c;
    border-radius: 5px;
    margin-bottom: 20px;
}