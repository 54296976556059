.table {
    
    border: 0.5px solid #CCCDCF;
    width: 95%;
    margin-left: 10px;
    margin-right: -10px;
  }
  
  .tableRowHeader {
    background-color: transparent;
    transition: all 0.25s ease;
    
  }
  
  .tableHeader {
    background-color: #CD3B2C;
    padding: 12px;
    font-weight: bold;
    text-align: left;
    font-size: 14px;
    color: #FFFFFF;
  }
  
  .tableHeader:first-child {
    border-top-left-radius: 2px;
  }
  
  .tableHeader:last-child {
    border-top-right-radius: 2px;
  }
  
  .tableRowItems {
    cursor: auto;
    background-color: #FFFFFF;
    border-bottom: 0.5px solid #CCCDCF;
  }
 
  .tableCell {
    padding: 12px;
    font-size: 14px;
    color: #000000;
  }