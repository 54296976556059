 
.react-responsive-modal-modal {
    width: 320px;
    display: inline-block;
    text-align: left;
    vertical-align: middle;
    background: #ffffff;
    box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.25);
    margin: 1.2rem;
    padding: 1.2rem;
    position: relative;
    border-radius: 4px;
    overflow-y: visible !important;
}

.icon-model{
     
    position: absolute;
    top: -26px;
}

.react-responsive-modal-closeButton {
    position: absolute;
    top: -10px !important;
    right: -9px !important;
    background-color: #fff !important;
    border: 1px solid gray !important;
    border-radius: 18px;
    padding: 0;
    cursor: pointer;
    background-color: transparent;
    display: flex;
}

.react-responsive-modal-closeButton svg{
    width: 20px !important;
    height: 20px !important;
}

.action-modal {
    font-size: 12px;
    padding: 3px;
}